import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../../../theme";
import CameraPermissionsIcon from "../../../../theme/icons/CameraPermissionsIcon";

import CircledBackButton from "../../../CircledBackButton";

interface AwaitCameraPermissionsProps {
  onBackButtonClick: Function;
}

export default function AwaitCameraPermissions({
  onBackButtonClick,
}: AwaitCameraPermissionsProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        p={2}
        sx={{
          backgroundColor: "white",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>

        <Stack
          height="100%"
          spacing={4}
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <CameraPermissionsIcon
            sx={{ height: theme.spacing(16), width: theme.spacing(16) }}
          />

          <Typography variant="h2">
            {t("BinahScanFlow.awaitingCameraPermissions.title")}
          </Typography>

          <Stack spacing={1}>
            {(
              t<any, any, string[]>(
                "BinahScanFlow.awaitingCameraPermissions.body",
                {
                  returnObjects: true,
                }
              ) as []
            ).map((text: string, index: number) => (
              <Typography key={String(text + index)}>{text}</Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
