import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import {
  options as ZAIDNumberValidatorOptions,
  isValid as isValidZAIDNumber,
} from "../../../../utils/validators/ZAIDNumber/ZAIDNumber";

import { isValid as isValidPolicyNumber } from "../../../../utils/validators/PolicyNumber/PolicyNumber";

interface CollectUserPersonalInformationProps {
  onChange: (value: any) => void;
}

export default function CollectUserPersonalInformation({
  onChange,
}: CollectUserPersonalInformationProps) {
  const { t } = useTranslation();
  const idNumberInputID = useId();
  const policyNumberInputID = useId();
  const firstNameInputID = useId();
  const lastNameInputID = useId();

  const [idNumber, setIdNumber] = useState<number | undefined>();
  const [policyNumber, setPolicyNumber] = useState<number | undefined>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    onChange({
      isValid:
        Boolean(isValidZAIDNumber(idNumber)) &&
        Boolean(firstName) &&
        Boolean(lastName) &&
        Boolean(isValidPolicyNumber(policyNumber)),
      data: {
        idNumber,
        firstName,
        lastName,
        policyNumber,
      },
    });
  }, [idNumber, policyNumber, lastName, firstName]);

  function santiseIdentificationValue(value: any) {
    return String(value).replace(/\D/g, "");
  }

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstName(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setLastName(value);
  }

  function onIdNumberInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;

    setIdNumber(santiseIdentificationValue(value) as any);
  }

  function onPolicyNumberInput(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { value } = event.target;

    setPolicyNumber(value as any);
  }

  return (
    <Stack spacing={4} justifyContent="flex-end">
      <form>
        <Stack spacing={2} justifyContent="flex-end">
          <Stack spacing={2} sx={{ pb: 5 }}>
            <Stack spacing={2}>
              <Typography variant="h3">
                {t("PublicBinahScanFlow.collectUserPersonalInformation.title")}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={idNumberInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("common.IdNumber")}
              </Typography>
            </InputLabel>
            <TextField
              id={idNumberInputID}
              onChange={onIdNumberInput}
              value={idNumber}
              error={Boolean(idNumber && !isValidZAIDNumber(idNumber))}
              inputProps={{
                autoComplete: "off",
                inputMode: "numeric",
                maxLength: ZAIDNumberValidatorOptions.maxLength,
                "aria-label": t(
                  "CollectIdentificationNumber.options.IdNumber.inputLabel"
                ),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={firstNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("FirstNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={firstNameInputID}
              value={firstName}
              onInput={onFirstNameInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t("FirstNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={lastNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("LastNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={lastNameInputID}
              value={lastName}
              onInput={onLastNameInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t("LastNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={policyNumberInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t(
                  "PublicBinahScanFlow.collectUserPersonalInformation.policyNumber"
                )}
              </Typography>
            </InputLabel>
            <TextField
              id={policyNumberInputID}
              value={policyNumber}
              onInput={onPolicyNumberInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t(
                  "PublicBinahScanFlow.collectUserPersonalInformation.policyNumber"
                ),
              }}
              error={Boolean(
                policyNumber && !isValidPolicyNumber(policyNumber)
              )}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
