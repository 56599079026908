import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../../../theme/icons/FaceIconBrandLarge";

interface PromptProps {
  onAccept: Function;
  onDecline: Function;
}

export default function Prompt(props: PromptProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        p={2}
        spacing={4}
        justifyContent="flex-end"
        sx={{
          flex: "1 1 auto",
          overflowX: "hidden",
        }}
      >
        <FaceIconBrandLarge />

        <Typography variant="h3">
          {t("PublicBinahScanFlow.prompt.title")}
        </Typography>

        <Stack spacing={1}>
          {(
            t<any, any, string[]>("PublicBinahScanFlow.prompt.body", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography key={String(text + index)}>{text}</Typography>
          ))}
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              props.onDecline();
            }}
          >
            {t("PublicBinahScanFlow.prompt.skipScanButton")}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              props.onAccept();
            }}
          >
            {t("common.continueButton")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
